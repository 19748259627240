import * as React from 'react'
import Layout from '../components/layout'
import {Container, Row, Col} from "react-bootstrap"
import {Link} from "gatsby"
import notFound from '../images/thumbnail-test.png'


const NotFoundPage = () => {
  return (
    <Layout pageTitle="404">
      <div className='home'>
        <Container>
        <Link to='/'>
          <Row className="align-items-center">
            <Col sm={4}>
              <Row>
                <h1>404</h1>
              </Row>
              <Row>
                <h2>Oops something went wrong, it looks like the page your looking for doesn't exist.</h2>
                <p>← Go back to the Homepage</p>
              </Row>
            </Col>
            <Col sm={8}>
            <img src={notFound} alt="not found"/>
            </Col>
          </Row>
          </Link>
        </Container>
      </div>
    </Layout>
  )
}
export default NotFoundPage