import * as React from 'react'
import "../components/style.scss"
import Navbar from './navBar/navBar'
import Footer from './footer/footer'

const Layout = ({ pageTitle, children }) => {
  return (
    <div>
      <title>{pageTitle} | Studio Coat</title>
      <Navbar />
      <main style={{
        minHeight: '77vh',
      }}>
        {children}
      </main>
      <Footer />
    </div>
  )
}
export default Layout